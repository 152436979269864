/* TEMPLATE STYLES */
@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"), local("Proxima Nova"),
    url(../../font/Proxima-Nova-Reg.otf) format("opentype");
}
@font-face {
  font-family: "Proxima Nova Bold";
  src: local("Proxima Nova Bold"), local("Proxima Nova Bold"),
    url(../../font/Proxima-Nova-Bold.otf) format("opentype");
}
html {
  font-family: "Proxima Nova" !important;
}
body {
  font-family: "Proxima Nova" !important;
  position: relative;
  overflow-y: scroll;
  /*user-select: none;
            -webkit-touch-callout: none;
            -webkit-user-select: none;
            -khtml-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;*/
}
.border-left-blue {
  border-left: #2196f3 solid 5px;
}
.border-left-green {
  border-left: #4caf50 solid 5px;
}
.border-left-orange {
  border-left: #ff9800 solid 5px;
}
.panel-inicio {
  height: 280px;
  width: 280px;
  border-radius: 50%;
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.panel-icon-big {
  position: absolute;
  font-size: 10em;
  opacity: 0.3;
  padding-top: 0.3em;
}
.display-5 {
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 1.2;
}
.wrapper {
  position: relative;
  top: 0;
  height: 100vh;
}
.wrapper.wrapper-full-page {
  min-height: 100vh;
  height: auto;
}
.wrapper.wrapper-full-page {
  min-height: 100vh;
  height: auto;
}
.full-page > .content,
.full-page > .footer {
  position: relative;
  z-index: 4;
}

.full-page.section-image {
  position: initial;
}

.full-page > .content {
  padding-top: 21vh;
}

.full-page .full-page-background {
  position: absolute;
  z-index: 1;
  height: 100%;
  width: 100%;
  display: block;
  top: 0;
  left: 0;
  background-size: cover;
  background-position: center center;
}

.full-page .footer {
  position: absolute;
  width: 100%;
  bottom: 0;
}
.full-page .footer .container {
  color: #ffffff;
}

.full-page .full-page-background {
  position: absolute;
  z-index: 1;
  height: 100%;
  width: 100%;
  display: block;
  top: 0;
  left: 0;
  background-size: cover;
  background-position: center center;
}

.full-page:after {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: block;
  left: 0;
  top: 0;
  content: "";
  background-color: rgba(0, 0, 0, 0.6);
}

.full-page.pricing-page .description {
  margin-bottom: 65px;
}

.full-page.register-page .content > .container {
  margin-top: 5vh;
}

.full-page.register-page .info-horizontal {
  padding: 0px 0px 20px;
}

.full-page.register-page .info-horizontal {
  text-align: left !important;
}
.full-page.register-page .info-horizontal .icon {
  margin-top: 0;
}
.full-page.register-page .info-horizontal .icon > i {
  font-size: 2em;
}
.full-page.register-page .info-horizontal .icon.icon-circle {
  width: 65px;
  height: 65px;
  max-width: 65px;
  margin-top: 8px;
}
.full-page.register-page .info-horizontal .icon.icon-circle i {
  display: table;
  margin: 0 auto;
  line-height: 3.5;
  font-size: 1.9em;
}
.full-page.register-page .info-horizontal .description {
  overflow: hidden;
}

.full-page.lock-page .content > .container {
  margin-top: 10vh;
}
.login-page .content .container {
  margin-top: 8vh;
}

.login-page .card-login {
  border-radius: 6px;
  padding-bottom: 0.7rem;
}
.login-page .card-login .btn-wd {
  min-width: 180px;
}
.login-page .card-login .logo-container {
  width: 65px;
  margin: 0 auto;
  margin-bottom: 55px;
}
.login-page .card-login .logo-container img {
  width: 100%;
}
.login-page .card-login .input-group:last-child {
  margin-bottom: 40px;
}
.login-page .card-login.card-plain .form-control::-moz-placeholder {
  color: #ebebeb;
  opacity: 1;
  filter: alpha(opacity=100);
}
.login-page .card-login.card-plain .form-control:-moz-placeholder {
  color: #ebebeb;
  opacity: 1;
  filter: alpha(opacity=100);
}
.login-page .card-login.card-plain .form-control::-webkit-input-placeholder {
  color: #ebebeb;
  opacity: 1;
  filter: alpha(opacity=100);
}
.login-page .card-login.card-plain .form-control:-ms-input-placeholder {
  color: #ebebeb;
  opacity: 1;
  filter: alpha(opacity=100);
}
.login-page .card-login.card-plain .form-control {
  border-color: rgba(255, 255, 255, 0.5);
  color: #ffffff;
}
.login-page .card-login.card-plain .form-control:focus {
  border-color: #ffffff;
  background-color: transparent;
  color: #ffffff;
}
.login-page .card-login.card-plain .has-success:after,
.login-page .card-login.card-plain .has-danger:after {
  color: #ffffff;
}
.login-page .card-login.card-plain .has-danger .form-control {
  background-color: transparent;
}
.login-page .card-login.card-plain .input-group-prepend .input-group-text,
.login-page .card-login.card-plain .input-group-append .input-group-text {
  background-color: transparent;
  border-color: rgba(255, 255, 255, 0.5);
  color: #ffffff;
}
.login-page
  .card-login.card-plain
  .input-group-focus
  .input-group-prepend
  .input-group-text,
.login-page
  .card-login.card-plain
  .input-group-focus
  .input-group-append
  .input-group-text {
  background-color: transparent;
  border-color: #ffffff;
  color: #ffffff;
}
.login-page .card-login.card-plain .form-group.no-border .form-control,
.login-page .card-login.card-plain .input-group.no-border .form-control {
  background-color: rgba(255, 255, 255, 0.1);
  color: #ffffff;
}
.login-page .card-login.card-plain .form-group.no-border .form-control:focus,
.login-page .card-login.card-plain .form-group.no-border .form-control:active,
.login-page .card-login.card-plain .form-group.no-border .form-control:active,
.login-page .card-login.card-plain .input-group.no-border .form-control:focus,
.login-page .card-login.card-plain .input-group.no-border .form-control:active,
.login-page .card-login.card-plain .input-group.no-border .form-control:active {
  background-color: rgba(255, 255, 255, 0.2);
  color: #ffffff;
}
.login-page
  .card-login.card-plain
  .form-group.no-border
  .form-control
  + .input-group-prepend
  .input-group-text,
.login-page
  .card-login.card-plain
  .form-group.no-border
  .form-control
  + .input-group-append
  .input-group-text,
.login-page
  .card-login.card-plain
  .input-group.no-border
  .form-control
  + .input-group-prepend
  .input-group-text,
.login-page
  .card-login.card-plain
  .input-group.no-border
  .form-control
  + .input-group-append
  .input-group-text {
  background-color: rgba(255, 255, 255, 0.1);
}
.login-page
  .card-login.card-plain
  .form-group.no-border
  .form-control
  + .input-group-prepend
  .input-group-text:focus,
.login-page
  .card-login.card-plain
  .form-group.no-border
  .form-control
  + .input-group-prepend
  .input-group-text:active,
.login-page
  .card-login.card-plain
  .form-group.no-border
  .form-control
  + .input-group-prepend
  .input-group-text:active,
.login-page
  .card-login.card-plain
  .form-group.no-border
  .form-control
  + .input-group-append
  .input-group-text:focus,
.login-page
  .card-login.card-plain
  .form-group.no-border
  .form-control
  + .input-group-append
  .input-group-text:active,
.login-page
  .card-login.card-plain
  .form-group.no-border
  .form-control
  + .input-group-append
  .input-group-text:active,
.login-page
  .card-login.card-plain
  .input-group.no-border
  .form-control
  + .input-group-prepend
  .input-group-text:focus,
.login-page
  .card-login.card-plain
  .input-group.no-border
  .form-control
  + .input-group-prepend
  .input-group-text:active,
.login-page
  .card-login.card-plain
  .input-group.no-border
  .form-control
  + .input-group-prepend
  .input-group-text:active,
.login-page
  .card-login.card-plain
  .input-group.no-border
  .form-control
  + .input-group-append
  .input-group-text:focus,
.login-page
  .card-login.card-plain
  .input-group.no-border
  .form-control
  + .input-group-append
  .input-group-text:active,
.login-page
  .card-login.card-plain
  .input-group.no-border
  .form-control
  + .input-group-append
  .input-group-text:active {
  background-color: rgba(255, 255, 255, 0.2);
  color: #ffffff;
}
.login-page
  .card-login.card-plain
  .form-group.no-border
  .form-control:focus
  + .input-group-prepend
  .input-group-text,
.login-page
  .card-login.card-plain
  .form-group.no-border
  .form-control:focus
  + .input-group-append
  .input-group-text,
.login-page
  .card-login.card-plain
  .input-group.no-border
  .form-control:focus
  + .input-group-prepend
  .input-group-text,
.login-page
  .card-login.card-plain
  .input-group.no-border
  .form-control:focus
  + .input-group-append
  .input-group-text {
  background-color: rgba(255, 255, 255, 0.2);
  color: #ffffff;
}
.login-page
  .card-login.card-plain
  .form-group.no-border
  .input-group-prepend
  .input-group-text,
.login-page
  .card-login.card-plain
  .form-group.no-border
  .input-group-append
  .input-group-text,
.login-page
  .card-login.card-plain
  .input-group.no-border
  .input-group-prepend
  .input-group-text,
.login-page
  .card-login.card-plain
  .input-group.no-border
  .input-group-append
  .input-group-text {
  background-color: rgba(255, 255, 255, 0.1);
  border: none;
  color: #ffffff;
}
.login-page
  .card-login.card-plain
  .form-group.no-border.input-group-focus
  .input-group-prepend
  .input-group-text,
.login-page
  .card-login.card-plain
  .form-group.no-border.input-group-focus
  .input-group-append
  .input-group-text,
.login-page
  .card-login.card-plain
  .input-group.no-border.input-group-focus
  .input-group-prepend
  .input-group-text,
.login-page
  .card-login.card-plain
  .input-group.no-border.input-group-focus
  .input-group-append
  .input-group-text {
  background-color: rgba(255, 255, 255, 0.2);
  color: #ffffff;
}
.login-page .card-login.card-plain .input-group-addon,
.login-page .card-login.card-plain .form-group.no-border .input-group-addon,
.login-page .card-login.card-plain .input-group.no-border .input-group-addon {
  color: rgba(255, 255, 255, 0.8);
}

.login-page .link {
  font-size: 10px;
  color: #ffffff;
  text-decoration: none;
}
img {
  border-radius: 0px !important;
}
.buttons-excel {
  background-color: #4caf50 !important;
  border-radius: 10em !important;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12) !important;
}
.btn-group:hover > .dropdown-menu {
  display: block;
}
a.disabled {
  opacity: 0.65;
}
.highcharts-range-selector,
.highcharts-range-input,
.highcharts-range-label {
  /*transform: none!important;*/
}
/*.dropdown-menu .btn-group:hover >.dropdown-menu {
		  display: block;
		}*/
.btn-group > .dropdown-toggle:active {
  pointer-events: none;
}
.dropdown-item.btn-group {
  padding: 0;
}
.dropdown-menu {
  padding: 0;
  border-radius: 0;
  margin: 0;
}
.dropdown-submenu {
  top: 0;
  left: 100%;
}
#gto-map-fondo {
  position: absolute;
  height: 477px;
  background-image: url("../../img/Logos_SEDESHU-Veda Bco.png");
  background-repeat: no-repeat;
  background-position: right;
  background-size: auto 110%;
  opacity: 0.2;
}
.dotted-scrollspy {
  display: block;
  position: fixed;
  top: 10rem;
  right: 0;
  -moz-transform: translateX(73%);
  -ms-transform: translateX(73%);
  -o-transform: translateX(73%);
  transform: translateX(73%);
  background: rgba(255, 255, 255, 0);
  border-radius: 0px;
  z-index: 1040;
}
.btn-group .btn {
  border-radius: 0px;
}
.btn-floating {
  z-index: 1;
  overflow: hidden;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #000f9f;
  margin: 1em;
  cursor: pointer;
  -webkit-box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18),
    0 4px 15px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  display: inline-block;
}
.btn-floating:hover {
  background-color: #3b679c;
}
.btn-floating i {
  width: inherit;
  font-size: 1.5rem;
  line-height: 50px;
  text-align: center;
}
.btn-floating i {
  display: inline-block;
  color: #fff;
}
.btn-social-all i.fa {
  margin-top: 0;
}
.validation-summary-errors {
  color: red !important;
}
.nav-pills .nav-item a {
  /*transform: translateX(73%);*/
  text-align: left;
  color: #9e9e9e;
  border-radius: 0;
  border-left: 2px solid #fff;
  transition: transform 0.25s, background 0.25s;
}
.nav-pills .nav-item a i {
  margin-right: 0.2em;
}
.nav-pills .nav-item a:hover {
  border-left: 3px solid #0b4284;
  color: white;
  -moz-transform: translateX(-73%);
  -ms-transform: translateX(-73%);
  -o-transform: translateX(-73%);
  transform: translateX(-73%);
  background: #0159bc;
  position: relative;
  z-index: 1;
}
.nav-pills .nav-item a.active {
  color: #616161;
  border-left: 3px solid #0b4284;
  font-weight: 500;
}
.nav-pills .nav-item a i,
.nav-pills .nav-item a span {
  vertical-align: middle;
  padding: 0.1rem;
  padding-right: 0.4em;
}
main {
  padding-top: 3rem;
  padding-bottom: 2rem;
}

.extra-margins {
  margin-top: 1rem;
  margin-bottom: 2.5rem;
}
.jumbotron {
  text-align: center;
}
.dropdown-item:hover {
  background-color: #e0e0e0;
}
.navbar {
  background-color: #fff;
}
.navbar.navbar-dark .navbar-nav .nav-item a {
  color: #0b4284;
}
.navbar.navbar-dark .navbar-nav .nav-item a:hover {
  color: #000f9f;
}
.navbar.scrolling-navbar {
  -webkit-transition: background 0.5s ease-in-out, padding 0.5s ease-in-out;
  transition: background 0.5s ease-in-out, padding 0.5s ease-in-out;
  /*padding-top: 12px;
            padding-bottom: 12px;*/
}
.navbar.navbar-dark.scrolling-navbar.top-nav-collapse- .navbar-nav .nav-item a {
  /*no cambio color: #fff;*/
}
.navbar.scrolling-navbar.top-nav-collapse-,
.navbar.navbar-dark .navbar-toggler {
  margin-top: 0 !important;
  transition: margin 0.5s;
  /*padding-top: 5px;
            padding-bottom: 5px;*/
}
.navbar.scrolling-navbar.top-nav-collapse- img {
  width: 60%;
  transition: width 0.5s;
}
footer.page-footer {
  background-color: #dadada;
  color: #565656 !important;
}

.gto-blue {
  background-color: #051142 !important;
}

.gto-blue-med {
  background-color: #06f;
}
.gto-blue-med-1 {
  background-color: #06f;
  opacity: 0.9;
}
.gto-blue-med-2 {
  background-color: #06f;
  opacity: 0.8;
}

.gto-blue-med-3 {
  background-color: #06f;
  opacity: 0.7;
}

.gto-blue-med-4 {
  background-color: #06f;
  opacity: 0.6;
}

.gto-blue-darken-1 {
  background-color: #000f9f;
}
.gto-blue-darken-2 {
  background-color: #06f;
}
.gto-pink {
  background-color: #ff5ac8;
}
.gto-pink-1 {
  background-color: #ff5ac8;
  opacity: 0.9;
}
.gto-pink-2 {
  background-color: #ff5ac8;
  opacity: 0.8;
}
.gto-pink-3 {
  background-color: #ff5ac8;
  opacity: 0.7;
}
.gto-pink-4 {
  background-color: #ff5ac8;
  opacity: 0.6;
}
.gto-blue-text {
  color: #06f;
}
.gto-pink-text {
  color: #ff5ac8;
}
.text-blue-gto {
  color: #000f9f;
}

.text-blue-gto-med {
  color: #0066ff;
}
.radius-left {
  border-radius: 100px 0 0 100px;
}

.radius-right {
  border-radius: 0 100px 100px 0;
}
.radius {
  border-radius: 100px;
  padding: 0 1em 0 2em;
  margin: 0 !important;
}
.top-nav-collapse {
  padding-top: 3px;
  padding-bottom: 3px;
}
#navbarNav a {
  font-size: 14px;
  padding: 1em 1em;
  letter-spacing: 1px;
  line-height: 20px;
}
#nav.affix {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1030;
}
#panel-peb {
  background-color: rgba(238, 238, 238, 0.8);
  padding: 1em;
  margin: 1em;
  border-radius: 2px;
}
#panel-peb h1 {
  font-weight: 400;
  /*text-shadow: 0px 2px 5px #9e9e9e;*/
  color: #0b4284;
}
.fw-400 {
  font-weight: 400;
}
.text-blue {
  color: #0b4284;
}
.text-shadow {
  text-shadow: 5px 3px 5px #565656;
}
.text-shadow2 {
  text-shadow: 0px 2px 5px #9e9e9e;
  font-weight: 400;
}
.text-shadow3 {
  text-shadow: 1px 1px 1px #9e9e9e;
}
.card-block .h5 {
  font-weight: 400;
}
.tile {
  height: 230px;
}
.tooltip-inner {
  max-width: 900px !important;
}
/*chosen*/
.chosen-single {
  background-color: #fff !important;
}
.chosen-container .chosen-single,
.chosen-container-multi .chosen-choices {
  background-color: transparent;
  background-image: none;
  border: none;
  border-bottom-width: medium;
  border-bottom-style: none;
  border-bottom-color: currentcolor;
  border-bottom: 2px solid #ccc;
  border-radius: 0;
  outline: 0;
  height: 2.1rem;
  font-size: 1rem;
  box-shadow: none;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  transition: all 0.3s;
  color: #757575 !important;
}
.chosen-container .chosen-single .chosen-danger,
.chosen-danger input[type="text"] {
  border-bottom: 2px solid #ff4444;
}
.chosen-container,
.chosen-container-single,
.chosen-with-drop chosen-container-active .chosen-single {
  border: 0;
}
/*.chosen-container-single .chosen-single{
            border: none;
            border-top: 2px solid #0f0;
            border-radius: 0;
        }*/
.chosen-container-active.chosen-with-drop .chosen-single,
.chosen-container-active.chosen-with-drop .chosen-choices {
  background-image: none;
  border: none;
  border-radius: 0;
  border-bottom: 2px solid #478328;
}
.chosen-container .chosen-drop {
  background-image: none;
  border: none;
  border-radius: 0;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.chosen-container .chosen-results .active-result {
  font-size: 1em;
  padding: 1em;
}
.chosen-container .chosen-results,
.chosen-container-single .chosen-search {
  padding: 0.5em 1em;
}
.chosen-container-single .chosen-search input[type="text"] {
  padding: 0.5em;
}
.chosen-container .chosen-results li.highlighted {
  background-color: #478328;
  background-image: none;
  color: #fff;
}
.chosen-danger label {
  color: #f44336 !important;
}
.chosen-container .closen-danger {
  border-bottom: 2px solid #f44336 !important;
  color: #f44336 !important;
}
.chosen-container .closen-danger > span {
  color: #f44336 !important;
}
.md-form {
  margin-bottom: 1rem !important;
}
.md-form-sel {
  width: calc(100% - 3rem);
  /*margin-bottom: 1em;*/
}
.md-form-sel label {
  position: relative;
}
.multiselect- li a label {
  padding: 0.5em 1em;
  cursor: pointer;
  width: 100%;
  font-size: 1em;
}
.multiselect-container li:hover {
  background-color: #f5f5f5;
}
.multiselect-container li + .active {
  background-color: #4285f4;
  border-bottom: 1px solid #f5f5f5;
}
.multiselect-container li + .active a {
  color: #ffffff;
}
.md-form-multiselect .btn-group,
.md-form-multiselect .btn-group .multiselect {
  width: 100%;
}
.md-form-multiselect .btn-group .multiselect {
  font-size: 1em;
}
.md-form-multiselect .btn-group {
  border-bottom: 2px solid #ccc;
}
.md-form-multiselect .btn-group button {
  background-color: transparent;
  border: none;
  border-radius: 0;
  vertical-align: middle;
  color: #757575 !important;
  box-shadow: none;
  text-align: left;
}
.md-form-multiselect ul {
  width: 100%;
  padding: 0;
}
.md-form-multiselect .btn-group .btn-default,
.md-form-multiselect .btn-group button:hover,
.md-form-multiselect .btn-group.show button {
  background-color: transparent !important;
  cursor: pointer;
}
.md-form-multiselect .btn-group.show {
  border-bottom: 2px solid #4285f4;
}
/*datatables*/
.pagination {
  float: right;
}
.dataTables_filter {
  float: left;
}
.paginate_button a,
.dataTables_filter {
  color: #616161 !important;
  text-decoration: none;
}
.paginate_button {
  font-size: 0.8rem;
  padding: 0.85rem 2.13rem;
  margin: 6px;
  border-radius: 2px;
  border: 0;
  -webkit-transition: 0.2s ease-out;
  transition: 0.2s ease-out;
  white-space: normal !important;
  word-wrap: break-word;
  cursor: pointer;
}
.paginate_button.active {
  background-color: #039be5;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}
.paginate_button:hover {
  background-color: rgba(0, 0, 0, 0.05);
}
.paginate_button.active:hover {
  background-color: #039be5;
  cursor: default;
}
.paginate_button.active a {
  color: #fff !important;
}
.paginate_button.disabled {
  color: rgba(0, 0, 0, 0.26);
  cursor: default;
  background-color: transparent;
}
.dataTables_info,
.dataTables_length label {
  color: #616161 !important;
  font-size: 16px !important;
}
.custom-control-label {
  font-size: 1rem;
  cursor: pointer;
}
.modal-backdrop {
  background-color: #fafafa;
}
.modal-backdrop.show {
  opacity: 0.8;
  top: 6em;
}
/*hide scroll modal*/
body.modal-open {
  overflow: hidden !important;
}
[type="radio"] {
  visibility: hidden;
  margin-left: -1em;
}
[type="radio"] + i {
  position: absolute;
}
[type="radio"] + i + label {
  padding-left: 3em;
  cursor: pointer;
  z-index: 1000;
  margin: 1em 0;
}
[type="radio"]:checked + i {
  color: #4285f4;
  z-index: 0;
  background-color: #4285f4;
  border-radius: 50%;
  width: 0.9em;
  height: 0.9em;
  margin-top: -1px;
  padding-left: 1px;
}
.table-scrollx {
  overflow-x: scroll;
  width: 100%;
  transform: rotateX(180deg);
}
.table-scrollx table {
  transform: rotateX(180deg);
  /*display:table-cell;*/
}
.card-header {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.w-08 {
  width: 8%;
}
div.dataTables_processing {
  position: absolute;
  top: 0;
  left: 50%;
  width: 100%;
  margin-left: -50%;
  margin-top: -25px;
  /*text-align: center;*/
  font-size: 2em;
  height: 60px;
  padding-top: 10px;
  /*background:linear-gradient(to right, rgba(255,255,255,0) 0%, #093b76 25%, #093b76 75%, rgba(255,255,255,0) 100%);*/
  color: #fff;
}
.h-100 {
  height: 100%;
}
.item-scrollspy {
  margin-top: -125px;
  background-color: transparent;
  height: 125px;
}
.position-relative {
  position: relative;
  z-index: 1;
}
.blue-gradient {
  background: #000;
  background: -moz-linear-gradient(-45deg, #000 0, #062a64 100%);
  background: -webkit-linear-gradient(-45deg, #000 0, #062a64 100%);
  background: linear-gradient(135deg, #0262c9 0, #0159bc 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#062a64', GradientType=1 );
}
#carousel-one .carousel-item img {
  filter: grayscale(100);
  opacity: 0.4;
}
#carousel-one .carousel-item img:hover {
  filter: initial;
  opacity: 0.9;
}
#gto-map-fondo {
  height: 500px;
  background-position: left;
  background-size: auto 110%;
  filter: grayscale(100);
}
h1 {
  color: #0d47a1 !important;
}
.bg-blue-f,
.nav-bar,
.gto-blue-darken-1 {
  background-color: #0159bc !important;
}
.bg-blue-s,
.fixed-top {
  background-color: #0262c9;
}
.navbar.navbar-dark .navbar-nav .nav-item a,
.navbar.navbar-dark .navbar-nav .nav-item a:hover {
  color: white;
  text-transform: uppercase;
  letter-spacing: normal !important;
  font-weight: 400;
  padding: 1em !important;
}
.navbar.navbar-dark .navbar-nav .nav-item:hover {
  background-color: #000f9f;
}
@media (min-width: 1200px) {
  #carousel-example-1z,
  #carousel-example-1z .carousel-inner,
  #carousel-example-1z .carousel-item {
    height: 360px;
  }
}
@media (min-width: 992px) {
  #carousel-example-1z,
  #carousel-example-1z .carousel-inner,
  #carousel-example-1z .carousel-item {
    height: 360px;
  }
  .modal-lg {
    max-width: 680px;
  }
}
@media (min-width: 768px) {
  #carousel-example-1z,
  #carousel-example-1z .carousel-inner,
  #carousel-example-1z .carousel-item {
    height: 340px;
  }
}
@media (min-width: 576px) {
  #carousel-example-1z,
  #carousel-example-1z .carousel-inner,
  #carousel-example-1z .carousel-item {
    height: 360px;
  }
}
/*carousel-3d*/
.container-3d {
  padding-top: 4em;
  margin: 0 auto;
  position: relative;
}
.carousel-3d {
  width: 100%;
  height: 100%;
  position: absolute;
  transform-style: preserve-3d;
  transition: transform 1s;
}
.item-3d {
  margin-top: 1em;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  line-height: 200px;
  opacity: 0.95;
}
.item-3d img {
  width: 100%;
  height: 100%;
}
@media (max-width: 2000px) {
  .container-3d {
    width: 450px;
    height: 550px;
    perspective: 2000px;
  }
  .a {
    transform: rotateY(0deg) translateZ(450px);
  }
  .b {
    transform: rotateY(60deg) translateZ(450px);
  }
  .c {
    transform: rotateY(120deg) translateZ(450px);
  }
  .d {
    transform: rotateY(180deg) translateZ(450px);
  }
  .e {
    transform: rotateY(240deg) translateZ(450px);
  }
  .f {
    transform: rotateY(300deg) translateZ(450px);
  }
  .modal-backdrop {
    top: 10.2rem;
  }
  /*.navbar-brand img, .logo{
        width:90%;
    }*/
}
@media (max-width: 1200px) {
  .container-3d {
    width: 300px;
    height: 400px;
    perspective: 1200px;
  }
  .a {
    transform: rotateY(0deg) translateZ(300px);
  }
  .b {
    transform: rotateY(60deg) translateZ(300px);
  }
  .c {
    transform: rotateY(120deg) translateZ(300px);
  }
  .d {
    transform: rotateY(180deg) translateZ(300px);
  }
  .e {
    transform: rotateY(240deg) translateZ(300px);
  }
  .f {
    transform: rotateY(300deg) translateZ(300px);
  }
  .modal-backdrop {
    top: 10rem;
  }
  /*.navbar-brand img, .logo{
        width:90%;
    }*/
  .hidden-lg-down {
    display: none !important;
  }
}
@media (max-width: 992px) {
  .container-3d {
    width: 250px;
    height: 300px;
    perspective: 1000px;
  }
  .a {
    transform: rotateY(0deg) translateZ(250px);
  }
  .b {
    transform: rotateY(60deg) translateZ(250px);
  }
  .c {
    transform: rotateY(120deg) translateZ(250px);
  }
  .d {
    transform: rotateY(180deg) translateZ(250px);
  }
  .e {
    transform: rotateY(240deg) translateZ(250px);
  }
  .f {
    transform: rotateY(300deg) translateZ(250px);
  }
  .modal-backdrop {
    top: 9rem;
  }
  /*.navbar-brand img, .logo{
        width:70%;
    }*/
}
@media (max-width: 768px) {
  .container-3d {
    width: 250px;
    height: 300px;
    perspective: 1000px;
  }
  .a {
    transform: rotateY(0deg) translateZ(250px);
  }
  .b {
    transform: rotateY(60deg) translateZ(250px);
  }
  .c {
    transform: rotateY(120deg) translateZ(250px);
  }
  .d {
    transform: rotateY(180deg) translateZ(250px);
  }
  .e {
    transform: rotateY(240deg) translateZ(250px);
  }
  .f {
    transform: rotateY(300deg) translateZ(250px);
  }
  .modal-backdrop {
    top: 9rem;
  }
  /*.navbar-brand img, .logo{
        width:70%;
    }*/
}
@media (max-width: 576px) {
  .container-3d {
    width: 200px;
    height: 250px;
    perspective: 1000px;
  }
  .a {
    transform: rotateY(0deg) translateZ(200px);
  }
  .b {
    transform: rotateY(60deg) translateZ(200px);
  }
  .c {
    transform: rotateY(120deg) translateZ(200px);
  }
  .d {
    transform: rotateY(180deg) translateZ(200px);
  }
  .e {
    transform: rotateY(240deg) translateZ(200px);
  }
  .f {
    transform: rotateY(300deg) translateZ(200px);
  }
  .modal-backdrop {
    top: 8rem;
  }
  /*.navbar-brand img, .logo{
        width:60%;
    }*/
}

.actions-buttons-col {
  text-align: left !important;
}

.transparent-danger {
  color: #f44 !important;
  border-color: transparent;
  background-color: transparent;
  box-shadow: none;
}

.transparent-danger:active,
.transparent-danger:active:focus,
.transparent-danger:focus,
.transparent-danger:hover {
  color: #f44;
  border-color: #f44;
  background-color: transparent;
}

.card-filter:hover,
.card-filter-active {
  background-color: #000f9f !important;
  color: white !important;
  border-radius: 3px;
}

.card-filter-active:hover {
  background-color: #9584bf !important;
  color: white !important;
  border-radius: 3px !important;
}

.filter-icon:hover,
.filter-icon-active {
  color: white !important;
}

.filter-card {
  text-align: center;
  margin: 1%;
}

.row-filter {
  margin-bottom: 0.15% !important;
  margin-top: 0.75% !important;
}

.row-filter label {
  margin-top: 3% !important;
  font-size: medium !important;
}

.title {
  font-size: x-large;
  color: #093e92;
  text-align: center;
}

.scroll-div {
  max-height: 20vh;
  overflow-y: scroll;
  background-color: rgb(247, 247, 247);
}

.fixed-input {
  width: 100% !important;
}
.selectFilter {
  height: 20px !important;
  padding-top: 0% !important;
  padding-bottom: 0% !important;
}

.card-box {
  background-color: white;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  border-radius: 4px;
}

@font-face {
  font-family: "FuturaStd_1";
  src: url(../../assets/font/FuturaStd-CondensedLight.otf);
  font-weight: bold;
  font-style: bold;
}

@font-face {
  font-family: "FuturaStd_2";
  src: url(../../assets/font/FuturaStd-CondensedLightObl.otf);
  font-weight: bold;
  font-style: bold;
}

@font-face {
  font-family: "FuturaStd_3";
  src: url(../../assets/font/FuturaStd-CondensedOblique.otf);
  font-weight: bold;
  font-style: bold;
}

@font-face {
  font-family: "FuturaStd_4";
  src: url(../../assets/font/FuturaStd-CondExtraBoldObl.otf);
  font-weight: bold;
  font-style: bold;
}

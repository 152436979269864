
    .classic-tabs .nav {
        position: relative;
        overflow-x: auto;
        white-space: nowrap;
    }

    .card {
        border-radius: 0 0 .5rem .5rem;
    }

    .classic-tabs .nav li a.active {
        border-color: white;
    }

    .classic-tabs .nav li a.active {
        color: #fff;
        border-bottom: 10px solid;
        border-bottom-color: currentcolor;
    }
    .fc-highlight {
        /* when user is selecting cells */
        background: #bce8f1;
        opacity: .3;
        filter: alpha(opacity=30);
        /* for IE */ }

    .classic-tabs .nav li a {
        display: block;
        padding: 20px 24px;
        font-size: 13px;
        color: rgba(255,255,255,0.7);
        text-align: center;
        text-transform: uppercase;
        border-radius: 0;
    }
    .custom-file-label::after {
        content: 'ELEGIR';
        font-size: 1.1em;
        background-color: #4285F4;
        color: white;
        cursor: pointer;
        border: 0;
    }

    .ReactTable {
        position: relative;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        background-color: transparent; }
        .ReactTable.primary-pagination .-pagination .-btn {
          background-color: #0041A0;
          color: #FFFFFF; }
          .ReactTable.primary-pagination .-pagination .-btn:hover, .ReactTable.primary-pagination .-pagination .-btn:focus, .ReactTable.primary-pagination .-pagination .-btn:active, .ReactTable.primary-pagination .-pagination .-btn.active, .ReactTable.primary-pagination .-pagination .-btn:active:focus, .ReactTable.primary-pagination .-pagination .-btn:active:hover, .ReactTable.primary-pagination .-pagination .-btn.active:focus, .ReactTable.primary-pagination .-pagination .-btn.active:hover,
          .show > .ReactTable.primary-pagination .-pagination .-btn.dropdown-toggle,
          .show > .ReactTable.primary-pagination .-pagination .-btn.dropdown-toggle:focus,
          .show > .ReactTable.primary-pagination .-pagination .-btn.dropdown-toggle:hover {
            background-color: #002c6d !important;
            color: #FFFFFF !important;
            box-shadow: none !important; }
          .ReactTable.primary-pagination .-pagination .-btn:not([data-action]):hover {
            box-shadow: none; }
          .ReactTable.primary-pagination .-pagination .-btn.disabled, .ReactTable.primary-pagination .-pagination .-btn.disabled:hover, .ReactTable.primary-pagination .-pagination .-btn.disabled:focus, .ReactTable.primary-pagination .-pagination .-btn.disabled.focus, .ReactTable.primary-pagination .-pagination .-btn.disabled:active, .ReactTable.primary-pagination .-pagination .-btn.disabled.active, .ReactTable.primary-pagination .-pagination .-btn:disabled, .ReactTable.primary-pagination .-pagination .-btn:disabled:hover, .ReactTable.primary-pagination .-pagination .-btn:disabled:focus, .ReactTable.primary-pagination .-pagination .-btn:disabled.focus, .ReactTable.primary-pagination .-pagination .-btn:disabled:active, .ReactTable.primary-pagination .-pagination .-btn:disabled.active, .ReactTable.primary-pagination .-pagination .-btn[disabled], .ReactTable.primary-pagination .-pagination .-btn[disabled]:hover, .ReactTable.primary-pagination .-pagination .-btn[disabled]:focus, .ReactTable.primary-pagination .-pagination .-btn[disabled].focus, .ReactTable.primary-pagination .-pagination .-btn[disabled]:active, .ReactTable.primary-pagination .-pagination .-btn[disabled].active,
          fieldset[disabled] .ReactTable.primary-pagination .-pagination .-btn,
          fieldset[disabled] .ReactTable.primary-pagination .-pagination .-btn:hover,
          fieldset[disabled] .ReactTable.primary-pagination .-pagination .-btn:focus,
          fieldset[disabled] .ReactTable.primary-pagination .-pagination .-btn.focus,
          fieldset[disabled] .ReactTable.primary-pagination .-pagination .-btn:active,
          fieldset[disabled] .ReactTable.primary-pagination .-pagination .-btn.active {
            background-color: #0041A0;
            border-color: #0041A0; }
          .ReactTable.primary-pagination .-pagination .-btn.btn-simple {
            color: #0041A0;
            border-color: #0041A0; }
            .ReactTable.primary-pagination .-pagination .-btn.btn-simple:hover, .ReactTable.primary-pagination .-pagination .-btn.btn-simple:focus, .ReactTable.primary-pagination .-pagination .-btn.btn-simple:active {
              background-color: transparent;
              color: #002c6d;
              border-color: #002c6d;
              box-shadow: none; }
          .ReactTable.primary-pagination .-pagination .-btn.btn-link {
            color: #0041A0; }
            .ReactTable.primary-pagination .-pagination .-btn.btn-link:hover, .ReactTable.primary-pagination .-pagination .-btn.btn-link:focus, .ReactTable.primary-pagination .-pagination .-btn.btn-link:active, .ReactTable.primary-pagination .-pagination .-btn.btn-link:active:focus {
              background-color: transparent;
              color: #002c6d;
              text-decoration: none;
              box-shadow: none; }
          .ReactTable.primary-pagination .-pagination .-btn:disabled {
            opacity: 0.5;
            filter: alpha(opacity=50);
            pointer-events: none;
            cursor: not-allowed; }
        .ReactTable.info-pagination .-pagination .-btn {
          background-color: #51bcda;
          color: #FFFFFF; }
          .ReactTable.info-pagination .-pagination .-btn:hover, .ReactTable.info-pagination .-pagination .-btn:focus, .ReactTable.info-pagination .-pagination .-btn:active, .ReactTable.info-pagination .-pagination .-btn.active, .ReactTable.info-pagination .-pagination .-btn:active:focus, .ReactTable.info-pagination .-pagination .-btn:active:hover, .ReactTable.info-pagination .-pagination .-btn.active:focus, .ReactTable.info-pagination .-pagination .-btn.active:hover,
          .show > .ReactTable.info-pagination .-pagination .-btn.dropdown-toggle,
          .show > .ReactTable.info-pagination .-pagination .-btn.dropdown-toggle:focus,
          .show > .ReactTable.info-pagination .-pagination .-btn.dropdown-toggle:hover {
            background-color: #2ba9cd !important;
            color: #FFFFFF !important;
            box-shadow: none !important; }
          .ReactTable.info-pagination .-pagination .-btn:not([data-action]):hover {
            box-shadow: none; }
          .ReactTable.info-pagination .-pagination .-btn.disabled, .ReactTable.info-pagination .-pagination .-btn.disabled:hover, .ReactTable.info-pagination .-pagination .-btn.disabled:focus, .ReactTable.info-pagination .-pagination .-btn.disabled.focus, .ReactTable.info-pagination .-pagination .-btn.disabled:active, .ReactTable.info-pagination .-pagination .-btn.disabled.active, .ReactTable.info-pagination .-pagination .-btn:disabled, .ReactTable.info-pagination .-pagination .-btn:disabled:hover, .ReactTable.info-pagination .-pagination .-btn:disabled:focus, .ReactTable.info-pagination .-pagination .-btn:disabled.focus, .ReactTable.info-pagination .-pagination .-btn:disabled:active, .ReactTable.info-pagination .-pagination .-btn:disabled.active, .ReactTable.info-pagination .-pagination .-btn[disabled], .ReactTable.info-pagination .-pagination .-btn[disabled]:hover, .ReactTable.info-pagination .-pagination .-btn[disabled]:focus, .ReactTable.info-pagination .-pagination .-btn[disabled].focus, .ReactTable.info-pagination .-pagination .-btn[disabled]:active, .ReactTable.info-pagination .-pagination .-btn[disabled].active,
          fieldset[disabled] .ReactTable.info-pagination .-pagination .-btn,
          fieldset[disabled] .ReactTable.info-pagination .-pagination .-btn:hover,
          fieldset[disabled] .ReactTable.info-pagination .-pagination .-btn:focus,
          fieldset[disabled] .ReactTable.info-pagination .-pagination .-btn.focus,
          fieldset[disabled] .ReactTable.info-pagination .-pagination .-btn:active,
          fieldset[disabled] .ReactTable.info-pagination .-pagination .-btn.active {
            background-color: #51bcda;
            border-color: #51bcda; }
          .ReactTable.info-pagination .-pagination .-btn.btn-simple {
            color: #51bcda;
            border-color: #51bcda; }
            .ReactTable.info-pagination .-pagination .-btn.btn-simple:hover, .ReactTable.info-pagination .-pagination .-btn.btn-simple:focus, .ReactTable.info-pagination .-pagination .-btn.btn-simple:active {
              background-color: transparent;
              color: #2ba9cd;
              border-color: #2ba9cd;
              box-shadow: none; }
          .ReactTable.info-pagination .-pagination .-btn.btn-link {
            color: #51bcda; }
            .ReactTable.info-pagination .-pagination .-btn.btn-link:hover, .ReactTable.info-pagination .-pagination .-btn.btn-link:focus, .ReactTable.info-pagination .-pagination .-btn.btn-link:active, .ReactTable.info-pagination .-pagination .-btn.btn-link:active:focus {
              background-color: transparent;
              color: #2ba9cd;
              text-decoration: none;
              box-shadow: none; }
          .ReactTable.info-pagination .-pagination .-btn:disabled {
            opacity: 0.5;
            filter: alpha(opacity=50);
            pointer-events: none;
            cursor: not-allowed; }
        .ReactTable.success-pagination .-pagination .-btn {
          background-color: #0041A0;
          color: #FFFFFF; }
          .ReactTable.success-pagination .-pagination .-btn:hover, .ReactTable.success-pagination .-pagination .-btn:focus, .ReactTable.success-pagination .-pagination .-btn:active, .ReactTable.success-pagination .-pagination .-btn.active, .ReactTable.success-pagination .-pagination .-btn:active:focus, .ReactTable.success-pagination .-pagination .-btn:active:hover, .ReactTable.success-pagination .-pagination .-btn.active:focus, .ReactTable.success-pagination .-pagination .-btn.active:hover,
          .show > .ReactTable.success-pagination .-pagination .-btn.dropdown-toggle,
          .show > .ReactTable.success-pagination .-pagination .-btn.dropdown-toggle:focus,
          .show > .ReactTable.success-pagination .-pagination .-btn.dropdown-toggle:hover {
            background-color: #002c6d !important;
            color: #FFFFFF !important;
            box-shadow: none !important; }
          .ReactTable.success-pagination .-pagination .-btn:not([data-action]):hover {
            box-shadow: none; }
          .ReactTable.success-pagination .-pagination .-btn.disabled, .ReactTable.success-pagination .-pagination .-btn.disabled:hover, .ReactTable.success-pagination .-pagination .-btn.disabled:focus, .ReactTable.success-pagination .-pagination .-btn.disabled.focus, .ReactTable.success-pagination .-pagination .-btn.disabled:active, .ReactTable.success-pagination .-pagination .-btn.disabled.active, .ReactTable.success-pagination .-pagination .-btn:disabled, .ReactTable.success-pagination .-pagination .-btn:disabled:hover, .ReactTable.success-pagination .-pagination .-btn:disabled:focus, .ReactTable.success-pagination .-pagination .-btn:disabled.focus, .ReactTable.success-pagination .-pagination .-btn:disabled:active, .ReactTable.success-pagination .-pagination .-btn:disabled.active, .ReactTable.success-pagination .-pagination .-btn[disabled], .ReactTable.success-pagination .-pagination .-btn[disabled]:hover, .ReactTable.success-pagination .-pagination .-btn[disabled]:focus, .ReactTable.success-pagination .-pagination .-btn[disabled].focus, .ReactTable.success-pagination .-pagination .-btn[disabled]:active, .ReactTable.success-pagination .-pagination .-btn[disabled].active,
          fieldset[disabled] .ReactTable.success-pagination .-pagination .-btn,
          fieldset[disabled] .ReactTable.success-pagination .-pagination .-btn:hover,
          fieldset[disabled] .ReactTable.success-pagination .-pagination .-btn:focus,
          fieldset[disabled] .ReactTable.success-pagination .-pagination .-btn.focus,
          fieldset[disabled] .ReactTable.success-pagination .-pagination .-btn:active,
          fieldset[disabled] .ReactTable.success-pagination .-pagination .-btn.active {
            background-color: #0041A0;
            border-color: #0041A0; }
          .ReactTable.success-pagination .-pagination .-btn.btn-simple {
            color: #0041A0;
            border-color: #0041A0; }
            .ReactTable.success-pagination .-pagination .-btn.btn-simple:hover, .ReactTable.success-pagination .-pagination .-btn.btn-simple:focus, .ReactTable.success-pagination .-pagination .-btn.btn-simple:active {
              background-color: transparent;
              color: #002c6d;
              border-color: #002c6d;
              box-shadow: none; }
          .ReactTable.success-pagination .-pagination .-btn.btn-link {
            color: #0041A0; }
            .ReactTable.success-pagination .-pagination .-btn.btn-link:hover, .ReactTable.success-pagination .-pagination .-btn.btn-link:focus, .ReactTable.success-pagination .-pagination .-btn.btn-link:active, .ReactTable.success-pagination .-pagination .-btn.btn-link:active:focus {
              background-color: transparent;
              color: #002c6d;
              text-decoration: none;
              box-shadow: none; }
          .ReactTable.success-pagination .-pagination .-btn:disabled {
            opacity: 0.5;
            filter: alpha(opacity=50);
            pointer-events: none;
            cursor: not-allowed; }
        .ReactTable.warning-pagination .-pagination .-btn {
          background-color: #fbc658;
          color: #FFFFFF; }
          .ReactTable.warning-pagination .-pagination .-btn:hover, .ReactTable.warning-pagination .-pagination .-btn:focus, .ReactTable.warning-pagination .-pagination .-btn:active, .ReactTable.warning-pagination .-pagination .-btn.active, .ReactTable.warning-pagination .-pagination .-btn:active:focus, .ReactTable.warning-pagination .-pagination .-btn:active:hover, .ReactTable.warning-pagination .-pagination .-btn.active:focus, .ReactTable.warning-pagination .-pagination .-btn.active:hover,
          .show > .ReactTable.warning-pagination .-pagination .-btn.dropdown-toggle,
          .show > .ReactTable.warning-pagination .-pagination .-btn.dropdown-toggle:focus,
          .show > .ReactTable.warning-pagination .-pagination .-btn.dropdown-toggle:hover {
            background-color: #fab526 !important;
            color: #FFFFFF !important;
            box-shadow: none !important; }
          .ReactTable.warning-pagination .-pagination .-btn:not([data-action]):hover {
            box-shadow: none; }
          .ReactTable.warning-pagination .-pagination .-btn.disabled, .ReactTable.warning-pagination .-pagination .-btn.disabled:hover, .ReactTable.warning-pagination .-pagination .-btn.disabled:focus, .ReactTable.warning-pagination .-pagination .-btn.disabled.focus, .ReactTable.warning-pagination .-pagination .-btn.disabled:active, .ReactTable.warning-pagination .-pagination .-btn.disabled.active, .ReactTable.warning-pagination .-pagination .-btn:disabled, .ReactTable.warning-pagination .-pagination .-btn:disabled:hover, .ReactTable.warning-pagination .-pagination .-btn:disabled:focus, .ReactTable.warning-pagination .-pagination .-btn:disabled.focus, .ReactTable.warning-pagination .-pagination .-btn:disabled:active, .ReactTable.warning-pagination .-pagination .-btn:disabled.active, .ReactTable.warning-pagination .-pagination .-btn[disabled], .ReactTable.warning-pagination .-pagination .-btn[disabled]:hover, .ReactTable.warning-pagination .-pagination .-btn[disabled]:focus, .ReactTable.warning-pagination .-pagination .-btn[disabled].focus, .ReactTable.warning-pagination .-pagination .-btn[disabled]:active, .ReactTable.warning-pagination .-pagination .-btn[disabled].active,
          fieldset[disabled] .ReactTable.warning-pagination .-pagination .-btn,
          fieldset[disabled] .ReactTable.warning-pagination .-pagination .-btn:hover,
          fieldset[disabled] .ReactTable.warning-pagination .-pagination .-btn:focus,
          fieldset[disabled] .ReactTable.warning-pagination .-pagination .-btn.focus,
          fieldset[disabled] .ReactTable.warning-pagination .-pagination .-btn:active,
          fieldset[disabled] .ReactTable.warning-pagination .-pagination .-btn.active {
            background-color: #fbc658;
            border-color: #fbc658; }
          .ReactTable.warning-pagination .-pagination .-btn.btn-simple {
            color: #fbc658;
            border-color: #fbc658; }
            .ReactTable.warning-pagination .-pagination .-btn.btn-simple:hover, .ReactTable.warning-pagination .-pagination .-btn.btn-simple:focus, .ReactTable.warning-pagination .-pagination .-btn.btn-simple:active {
              background-color: transparent;
              color: #fab526;
              border-color: #fab526;
              box-shadow: none; }
          .ReactTable.warning-pagination .-pagination .-btn.btn-link {
            color: #fbc658; }
            .ReactTable.warning-pagination .-pagination .-btn.btn-link:hover, .ReactTable.warning-pagination .-pagination .-btn.btn-link:focus, .ReactTable.warning-pagination .-pagination .-btn.btn-link:active, .ReactTable.warning-pagination .-pagination .-btn.btn-link:active:focus {
              background-color: transparent;
              color: #fab526;
              text-decoration: none;
              box-shadow: none; }
          .ReactTable.warning-pagination .-pagination .-btn:disabled {
            opacity: 0.5;
            filter: alpha(opacity=50);
            pointer-events: none;
            cursor: not-allowed; }
        .ReactTable.danger-pagination .-pagination .-btn {
          background-color: #B90610;
          color: #FFFFFF; }
          .ReactTable.danger-pagination .-pagination .-btn:hover, .ReactTable.danger-pagination .-pagination .-btn:focus, .ReactTable.danger-pagination .-pagination .-btn:active, .ReactTable.danger-pagination .-pagination .-btn.active, .ReactTable.danger-pagination .-pagination .-btn:active:focus, .ReactTable.danger-pagination .-pagination .-btn:active:hover, .ReactTable.danger-pagination .-pagination .-btn.active:focus, .ReactTable.danger-pagination .-pagination .-btn.active:hover,
          .show > .ReactTable.danger-pagination .-pagination .-btn.dropdown-toggle,
          .show > .ReactTable.danger-pagination .-pagination .-btn.dropdown-toggle:focus,
          .show > .ReactTable.danger-pagination .-pagination .-btn.dropdown-toggle:hover {
            background-color: #91050d !important;
            color: #FFFFFF !important;
            box-shadow: none !important; }
          .ReactTable.danger-pagination .-pagination .-btn:not([data-action]):hover {
            box-shadow: none; }
          .ReactTable.danger-pagination .-pagination .-btn.disabled, .ReactTable.danger-pagination .-pagination .-btn.disabled:hover, .ReactTable.danger-pagination .-pagination .-btn.disabled:focus, .ReactTable.danger-pagination .-pagination .-btn.disabled.focus, .ReactTable.danger-pagination .-pagination .-btn.disabled:active, .ReactTable.danger-pagination .-pagination .-btn.disabled.active, .ReactTable.danger-pagination .-pagination .-btn:disabled, .ReactTable.danger-pagination .-pagination .-btn:disabled:hover, .ReactTable.danger-pagination .-pagination .-btn:disabled:focus, .ReactTable.danger-pagination .-pagination .-btn:disabled.focus, .ReactTable.danger-pagination .-pagination .-btn:disabled:active, .ReactTable.danger-pagination .-pagination .-btn:disabled.active, .ReactTable.danger-pagination .-pagination .-btn[disabled], .ReactTable.danger-pagination .-pagination .-btn[disabled]:hover, .ReactTable.danger-pagination .-pagination .-btn[disabled]:focus, .ReactTable.danger-pagination .-pagination .-btn[disabled].focus, .ReactTable.danger-pagination .-pagination .-btn[disabled]:active, .ReactTable.danger-pagination .-pagination .-btn[disabled].active,
          fieldset[disabled] .ReactTable.danger-pagination .-pagination .-btn,
          fieldset[disabled] .ReactTable.danger-pagination .-pagination .-btn:hover,
          fieldset[disabled] .ReactTable.danger-pagination .-pagination .-btn:focus,
          fieldset[disabled] .ReactTable.danger-pagination .-pagination .-btn.focus,
          fieldset[disabled] .ReactTable.danger-pagination .-pagination .-btn:active,
          fieldset[disabled] .ReactTable.danger-pagination .-pagination .-btn.active {
            background-color: #B90610;
            border-color: #B90610; }
          .ReactTable.danger-pagination .-pagination .-btn.btn-simple {
            color: #B90610;
            border-color: #B90610; }
            .ReactTable.danger-pagination .-pagination .-btn.btn-simple:hover, .ReactTable.danger-pagination .-pagination .-btn.btn-simple:focus, .ReactTable.danger-pagination .-pagination .-btn.btn-simple:active {
              background-color: transparent;
              color: #91050d;
              border-color: #91050d;
              box-shadow: none; }
          .ReactTable.danger-pagination .-pagination .-btn.btn-link {
            color: #B90610; }
            .ReactTable.danger-pagination .-pagination .-btn.btn-link:hover, .ReactTable.danger-pagination .-pagination .-btn.btn-link:focus, .ReactTable.danger-pagination .-pagination .-btn.btn-link:active, .ReactTable.danger-pagination .-pagination .-btn.btn-link:active:focus {
              background-color: transparent;
              color: #91050d;
              text-decoration: none;
              box-shadow: none; }
          .ReactTable.danger-pagination .-pagination .-btn:disabled {
            opacity: 0.5;
            filter: alpha(opacity=50);
            pointer-events: none;
            cursor: not-allowed; }
#historico {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: bold;
  border-collapse: collapse;
  color: #101410;
  width: 100%;
  /* background-image: url(../../assets/fondo_asamblea.png); */
}

td.top {
  vertical-align: top;
}

th.rosa {
  vertical-align: top;
  text-align: center;
  background-color: #f779ac;
}

#historico td,
#historico th {
  border: 1px solid #ddd;
  padding: 8px;
}

#historico tr:nth-child(even) {
  background-color: #f2f2f2;
}

#historico tr:hover {
  background-color: #ddd;
}
td.si {
  color: white;
}
td.no {
  color: rgb(15, 33, 236);
}
#historico th {
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: left;
  background-color: #0079c2;
  font-size: 12px;
  color: white;
}
#historico tr {
  font-size: 13px;
}

td.pan {
  background-color: #00579d;
  color: white;
}
td.pri {
  background-color: #e12314;
  color: white;
}
td.prd {
  background-color: #ffd829;
}
td.pt {
  background-color: #e12314;
}
td.pvem {
  background-color: #bde9ba;
}
td.mc {
  background-color: #f38703;
  color: white;
}

td.morena {
  background-color: #ad2c36;
  color: white;
}

td.na_gto {
  background-color: #0590a5;
  color: white;
}

td.rsp {
  background-color: #f35d76;
  color: white;
}

td.Switch {
  background-color: #e9ff58;
}

td.Dura {
  background-color: #00f38a;
}

td.Perdida {
  background-color: #fa2a4d;
  color: white;
}
